<template>
    <div class="orders-page">
        <div class="container-fluid">
            <h1>Zamówienia</h1>
            <div class="pr-2 d-print-none">
                <b-button class="btn mr-2 mb-2" :variant="selectedTypeAll ? 'primary': 'secondary'" @click.prevent="setSelectedTypeAll()">Wszystkie</b-button>
                <b-button class="btn mr-2 mb-2" :variant="selectedTypeOneTime ? 'primary': 'secondary'" @click.prevent="setSelectedTypeOneTime()">Jednorazowe</b-button>
                <b-button class="btn mr-2 mb-2" :variant="selectedTypeSubscription ? 'primary': 'secondary'" @click.prevent="setSelectedTypeSubscription()">Abonamentowe</b-button>
            </div>
            <div class="d-flex justify-content-between mt-3">
                <div class="pr-2 d-print-none">
                    <b-button class="btn mr-2 mb-2" :variant="selectedStatusAll ? 'primary': 'secondary'" @click.prevent="setSelectedStatusAll()">Wszystkie</b-button>
                    <b-button class="btn mr-2 mb-2" :variant="selectedStatusToPay ? 'primary': 'secondary'" @click.prevent="setSelectedStatusToPay()">Nie opłacone</b-button>
                    <b-button class="btn mr-2 mb-2" :variant="selectedStatusPaid ? 'primary': 'secondary'" @click.prevent="setSelectedStatusPaid()">Opłacone</b-button>
                    <b-button class="btn mr-2 mb-2" :variant="selectedStatusEnded ? 'primary': 'secondary'" @click.prevent="setSelectedStatusEnded()">Zakończone</b-button>
                    <b-button class="btn mr-2 mb-2" :variant="selectedStatusCanceled ? 'primary': 'secondary'" @click.prevent="setSelectedStatusCanceled()">Anulowane</b-button>
                </div>
                <div class="pr-2 flex-grow-1">
                    <input type="text" class="form-control" v-model="filter.search" placeholder="ID zamówienia" />
                </div>
                <b-pagination v-if="totalRows>perPage" 
                    v-model="currentPage"
                    :totalRows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
            </div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <b-table v-else :items="myDataProvider" :fields="fields" :filter="filter" :show-empty="true"
                    class="mt-2" striped outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                    :current-page="currentPage" :per-page="perPage"
                    sort-by="id"
                    :sort-desc="true"
                >
                <template #cell(options)="data">
                    <router-link :to="{name: 'AdminOrder',  params: { orderId: data.item.id } }" >
                        <button class="btn btn-secondary" >Zobacz</button>
                    </router-link>
                </template>
            </b-table>
            
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import { SCHEDULE_statusNew, SCHEDULE_statusToPay, SCHEDULE_statusPaid, SCHEDULE_statusEnded, SCHEDULE_statusCanceled, SCHEDULE_typeOneTimePamyent, SCHEDULE_typeSubscription } from "@/constants/constants.js";

export default {
    name: 'adminOrders',
    data() {
        return {
            isLoading: false,
            perPage: 25,
            currentPage: 1,
            totalRows: 0,
            filter: {
                status: SCHEDULE_statusToPay,
                search: '',
                type: null
            },
            fields: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Klient', key: 'user.fullname', sortable: true },
                { label: 'Adres', key: 'address_name', sortable: true },
                { label: 'Typ', key: 'typename', sortable: true },
                { label: 'Przedłużanie', key: 'can_extend_time', sortable: true },
                { label: 'Pierwsza dostawa', key: 'start_date', sortable: true },
                { label: 'Ostatnia dostawa', key: 'end_date', sortable: true },
                { label: 'Status zamówienia', key: 'statusname', sortable: true },
                { label: 'Data złożenia zamówienia', key: 'place_order_time', sortable: true },
                { label: 'Opakowanie', key: 'box_price', sortable: true },
                { label: 'Dostawa', key: 'delivery_price', sortable: true },
                { label: 'Suma', key: 'sum_price', sortable: true },
                { label: 'Status płatności', key: 'paid', sortable: true },
                { label: '', key: 'options', sortable: false }
            ]
        }
    },
    computed: {
        selectedStatusAll() {
            return this.filter.status == null;
        },
        selectedStatusNew() {
            return this.filter.status == SCHEDULE_statusNew;
        },
        selectedStatusToPay() {
            return this.filter.status == SCHEDULE_statusToPay;
        },
        selectedStatusPaid() {
            return this.filter.status == SCHEDULE_statusPaid;
        },
        selectedStatusEnded() {
            return this.filter.status == SCHEDULE_statusEnded;
        },
        selectedStatusCanceled() {
            return this.filter.status == SCHEDULE_statusCanceled;
        },
        selectedTypeOneTime() {            
            return this.filter.type == SCHEDULE_typeOneTimePamyent;
        },
        selectedTypeSubscription() {
            return this.filter.type == SCHEDULE_typeSubscription;
        },
        selectedTypeAll() {            
            return this.filter.type == null;
        },
    },
    methods: {
        setSelectedStatusAll() {
            this.filter.status = null;
        },
        setSelectedStatusToPay() {
            this.filter.status = SCHEDULE_statusToPay;
        },
        setSelectedStatusPaid() {
            this.filter.status = SCHEDULE_statusPaid;
        },
        setSelectedStatusEnded() {
            this.filter.status = SCHEDULE_statusEnded;
        },
        setSelectedStatusCanceled() {
            this.filter.status = SCHEDULE_statusCanceled;
        },
        setSelectedTypeOneTime() {
            this.filter.type = SCHEDULE_typeOneTimePamyent;
        },
        setSelectedTypeSubscription() {
            this.filter.type = SCHEDULE_typeSubscription;
        },
        setSelectedTypeAll() {
            this.filter.type = null;
        },
        myDataProvider(ctx) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            const promise = axios.post('/admin/schedules', {
                "perPage": ctx.perPage,
                "currentPage": ctx.currentPage,
                "sortBy": ctx.sortBy,
                "asc": ctx.sortDesc ? false : true,
                "filter": ctx.filter
            });

            return promise.then(result => {
                const items = result.data.schedules;
                this.totalRows = result.data.count || 0;
                return items || []
            })
        }
    }
}
</script>
